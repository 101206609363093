.custom-shape-divider-bottom-1653904320 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1653904320 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 94px;
}

.custom-shape-divider-bottom-1653904320 .shape-fill {
  fill: #ffffff;
}
.svg-fill-green .shape-fill {
  fill: #016d5b;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .custom-shape-divider-bottom-1653904320 svg {
    width: calc(100% + 1.3px);
    height: 60px;
  }
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1653904320 svg {
    width: calc(100% + 1.3px);
    height: 32px;
  }
}
